import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { PageSection, PageTextRectangle } from '../components'
import {
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
  sectionFive,
  sectionSix,
  sectionSeven,
} from '../assets/texts/home-page'
import { Link } from 'gatsby'

const HomeIndex = () => {
  return (
    <Layout>
      {/*  todo: update descriptions and keywords */}
      <Helmet
        title="Krasto Pažinimas"
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      >
      </Helmet>
      
      <div id="main">
        <PageSection>
          {/* todo: play with static image size props */}
          <StaticImage
            className="main-image"
            src="../assets/images/main_image.jpg"
            alt='krasto pazinimas'
            width={800}
          />
          
          <PageTextRectangle
            title={sectionOne.title}
            text={sectionOne.paragraph}
          />
        </PageSection>
        
        <PageSection className='section-2'>
          {sectionTwo.map(({ Svg, title, paragraph, link }) => (
            <Link key={title} to={link}>
              <PageTextRectangle
                title={title}
                text={paragraph}
              >
                <Svg />
              </PageTextRectangle>
            </Link>
          ))}
        </PageSection>
  
        <PageSection className='section-4'>
          {sectionFour.map(section => (
            <PageTextRectangle
              className='white-text-background'
              title={section.title}
              text={section.paragraph}
            />
          ))}
          
          {/*<div className='spacer' />*/}
        </PageSection>
        
        <PageSection>
          <div className="home-page-mosaic">
            <StaticImage
              className="main-image"
              src="../assets/images/krasto_pazinimas.jpg"
              alt='baznicia'
            />
  
            {sectionThree.map(({ title, paragraph }) => (
              <PageTextRectangle
                key={title}
                className='section-3'
                title={title}
                text={paragraph}
              />
            ))}
  
            <StaticImage
              className="main-image"
              src="../assets/images/edukacija.jpg"
              alt='kardaj'
            />
          </div>
        </PageSection>
        
        <PageSection className="section-5">
          <h2 className='title'>{sectionFive.title}</h2>
          
          <div className="content">
            <StaticImage
              src="../assets/images/edukacine_programa.jpg"
              alt='whatever'
            />
  
            <div className="home-page-mosaic">
              <PageTextRectangle
                text={sectionFive.paragraph1}
              />
  
              <StaticImage
                className="main-image"
                src="../assets/images/galerija_3.jpg"
                alt='nothing'
              />
              
              
              <StaticImage
                src="../assets/images/galerija_4.jpg"
                alt='go-away'
              />
              
              <PageTextRectangle
                text={sectionFive.paragraph2}
              />
            </div>
          </div>
        </PageSection>
  
        <PageSection className='section-6'>
          <PageTextRectangle
            className="white-text-background"
            title={sectionSix.title}
            text={sectionSix.paragraph}
          />
          
          <div>
            <StaticImage
              src="../assets/images/savanoryste_photo1.jpg"
              alt='savanoryste-photo1'
              width={600}
            />
            
            <StaticImage
              src="../assets/images/savanoryste_photo2.jpg"
              alt='savanoryste-photo2'
              width={600}
            />
          </div>
        </PageSection>
        
        {/*<PageSection className='section-7'>*/}
        {/*  {sectionSeven.map(({ numbering, title, paragraph}) => (*/}
        {/*    <PageTextRectangle*/}
        {/*      key={title}*/}
        {/*      numbering={numbering}*/}
        {/*      title={title}*/}
        {/*      text={paragraph}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</PageSection>*/}
      </div>
  
  </Layout>
  )
}
export default HomeIndex
