import NoteSvg from '../svg/note.svg'
import FolderSvg from '../svg/folder.svg'
import BookSvg from '../svg/book.svg'

export const sectionOne = {
  title: 'Krašto pažinimo ir tyrimų centras',
  paragraph: 'Krašto tyrimai, moksleivių edukacija, vaikų vasaros stovyklos'
}

export const sectionTwo = [
  {
    Svg: NoteSvg,
    title: 'Paslaugos',
    link: '/paslaugos',
    paragraph: 'Centras teikia paslaugas, susijusias su  krašto tyrimais ir taikomąja veikla, vaikų ir jaunimo užimtumu, švietimu.'
  },
  {
    Svg: FolderSvg,
    title: 'Mūsų veiklos istorijos',
    link: '/musu-veiklos-istorijos',
    paragraph: 'Krašto pažinimo ir tyrimų centras įgyvendina projektus, skirtus pilietiniam ugdymui, istorinės atminties įprasminimui, vaikų ir jaunimo socializacijai.'
  },
  {
    Svg: BookSvg,
    title: 'Edukacija',
    link: '/paslaugos',
    paragraph: 'Vaikų ir jaunimo ugdymas apima tris veiklos kryptis:  kraštotyrinę, edukacinę ir turistinę - tai yra visų jų simbiozė  turinti ribotus mokslinės krypties elementus.'
  },
]

export const sectionThree = [
  {
    title: 'Krašto pažinimas',
    paragraph: [
      'Mūsų kraštas - gimtinė, kurioje daug upių, ežerų, pelkių, miškų, daug gražių vietų apipintų legendomis, padavimais, tikrais ir išgalvotais įvykiais, piliakalnių, pilimis, dvarais ir jų liekanomis, mažais ir didesniais miesteliais, miestais, skirtingais etniniais regionais.',
      'Kraštotyra – tai savo krašto pažinimas. Vienas žymiausių Lietuvos filosofų A. Maceina pabrėžė, kad „kraštotyra yra pirmoji ir svarbiausia priemonė pažinti tautinei dabarčiai“.'
    ]
    // paragraph: 'Kraštotyra – tai savo krašto pažinimas. Vienas žymiausių Lietuvos filosofų A. Maceina pabrėžė, kad „kraštotyra yra pirmoji ir svarbiausia priemonė pažinti tautinei dabarčiai“.'
  },
  {
    title: 'Edukacija',
    paragraph: [
      'Mūsų veiklos yra įvairialypės, visapusiškai ugdančios. Pagrindinis edukacinių veiklų tikslas - ugdyti iniciatyvią, aktyvią, atsakingą asmenybę.'
    ]
  }
]

export const sectionFour = [
  {
    title: 'Mūsų tikslas',
    paragraph: 'Ugdyti jauno žmogaus savimonę, patriotinius jausmus Tėvynei, toleranciją  visoms tautoms, skiepyti savarankiškumą, pažinti ne tik gimtojo krašto, bet ir kaimyninių valstybių gamtinį kultūrinį - istorinį palikimą ir nacionalines vertybes.'
  },
  {
    title: 'Mes siekiame',
    paragraph: 'Mažinti kultūrinį intelektualinį, edukacinį atotrūkį tarp Lietuvos periferijos ir didmiesčių; skatinti tarpusavio veiklas, didinti jaunimo bendradarbiavimo galimybes; skatinti moksleivių kraštotyros ir kitokių būrelių atgaivinimą, plėtrą, bendras veiklas mokyklose, muziejų steigimą.'
  }
]

export const sectionFive = {
  title: 'Galerija',
  paragraph1: 'Užfiksuotas akimirkas rasite nuotraukų galerijoje',
  paragraph2: 'Vaikų ir jaunimo pažintinės ekskursijos, vasaros stovyklos, kūrybinės dirbtuvės'
}

export const sectionSix = {
  title: 'Savanorystė',
  paragraph: 'Norintys įgyti darbo patirties, pagilinti socialinius įgūdžius, realizuoti save, suteikti savo gyvenimui daugiau prasmės - susisiek su mumis.'
}

export const sectionSeven = [
  {
    title: '2021 m. vykdyti projektai',
    paragraph: [
      'Lietuvos kultūros tarybos dalinai finansuotas projektas "Lietuvai reikšmingo kultūros paveldo esančio Latvijos Respublikoje lankymas"',
      'Akmenės rajono savivaldybės finansuojamas projektas "Eksperimentinė archeologija"'
    ]
  },
  {
    title: '2020 m. vykdyti projektai',
    paragraph: [
      'Akmenės rajono savivaldybės finansuojamas projektas „Vidurio ir Rytų Lietuvos istorinio kultūrinio palikimo pėdomis“',
      'Akmenės rajono savivaldybės finansuojamas projektas „Aš galiu būti archeologu!“'
    ]
  }
]

// 3. Paslaugos - Įstaigos veiklos spektras orientuotas į:
//   * gamtos bei kultūros paveldo tyrimus (paieška, identifikavimas, pagalba tyrėjams) ir išsaugojimą;
// * naujų pažintinių maršrutų ir atmintinų vietų sudarymu;
// *pažintinių-edukacinių išvykų organizavimą;
// *jaunimo edukacinių, kultūros paveldo ir kraštortyros stovyklų plėtrą, naujų veiklų iniciatyvas;
// 4. Krašto pažinimas
// Mūsų kraštas - gimtinė, kurioje daug upių, ežerų, pelkių, miškų, daug gražių vietų apipintų legendomis, padavimais, tikrais ir išgalvotais įvykiais, piliakalnių, pilimis, dvarais ir jų liekanomis, mažais ir didesniais miesteliais, miestais, skirtingais etniniais regionais.
// 5. Tarpukario Lietuvos filosofas A. Maceina pažymėjo, kad "kraštotyra yra pirmoji ir svarbiausia priemonė pažinti tautinei dabarčiai"
// 6. Skyrelį "Projektai" pakeisti į "Mūsų veiklos istorijos".
